import React from 'react';
import * as S from './ScrollButtonContainer.styles';
import { Builder } from '@builder.io/react';

const ScrollButtonContainer = props => {
  const { children, positionX, positionY, scrollToElement, elementId } = props;

  const _positionX = positionX || 0;
  const _positionY = positionY || 0;

  const getOffset = el => {
    let _x = 0;
    let _y = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
      _x += el.offsetLeft - el.scrollLeft;
      _y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
    }
    return { top: _y, left: _x };
  };

  const triggerClick = () => {
    if (Builder.isEditing) return;
    if (scrollToElement) {
      window.scroll({
        top: getOffset(document.getElementById(elementId)).top,
        left: getOffset(document.getElementById(elementId)).left,
        behavior: 'smooth'
      });
    } else {
      window.scroll({
        top: _positionY,
        left: _positionX,
        behavior: 'smooth'
      });
    }
  };

  const label = scrollToElement
    ? `id: ${elementId}`
    : `x: ${_positionX}, y: ${_positionY}`;

  const noChildren = () => {
    return <S.NoChildren>insert components here</S.NoChildren>;
  };

  return (
    <S.Container
      onClick={triggerClick}
      layerId={label}
      editing={Builder.isEditing}
    >
      {children || noChildren()}
    </S.Container>
  );
};

export default ScrollButtonContainer;
